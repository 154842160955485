var app = {
	windowLoaded: function () {
		_main = this;
		time = $('.guest').length?2000:400;
		setTimeout(function(){
			$('.preloader').fadeOut(500, function () {
				// $(this).remove();
			});
		}, time);		
	},
	windowResized: function () {
		_main = this;
		_main.getViewport();
	},
	windowScrolled: function () {
		_main = this;
	},
	cookies: {		
		createCookie: function(name, value, days) {		    
		    _main=app;
		    var date = new Date();
		    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		    var expires = "; expires=" + date.toGMTString();
		    document.cookie = name + "=" + value + expires + "; path=/";
		    (_main.debug)?console.log('creating cookie: ',name):'';
		},
		readCookie: function(name) {
		    _main=app;
		    var nameEQ = name + "=";
		    var ca = document.cookie.split(';');
		    for (var i = 0; i < ca.length; i++) {
		    	var c = ca[i];
		    	while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		    	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		    }
		    return null;
		},
		checkCookies: function() {
		    _main=app;
		    if (_main.cookies.readCookie(_main.cookieName) != 1) {
		      (_main.debug)?console.log('cookie %s is not created yet',_main.cookieName):'';
		      _main.cookies.notification('show');
		    } else {
		    	(_main.debug)?console.log('cookie %s already exists',_main.cookieName):'';
		    }
		    $('.closeNotification').on('click',function () {
		      _main.cookies.createCookie(_main.cookieName, 1, 365);
		      _main.cookies.notification('hide');
		    });
		},
		notification: function(state) {
			_main=app;
			switch (state) {
				case 'show':
					$('footer').append('<div class="cookiesNotification"><p>Na stronie używamy plików cookies.</p><p class="closeNotification">OK &times;</p></div>');
				break;
				case 'hide':					
					$('.cookiesNotification').fadeOut(300, function () {
				        $(this).remove();
				      });
				break;
			}
		}
	},	
	handleSliders: function() {
		if ($('.slider-hero').length) {
			$('.slider-hero').slick({
				fade: true,
				arrows: false,
				dots: true,
				autoplay: true,
				autoplaySpeed: 8000,
				speed: 500,		
				pauseOnFocus: false,
				pauseOnHover: false,
				responsive: [
					{
					  	breakpoint: 640,
					  	settings: {
							vertical: false,
							swipe: true
					  	}
					}
				]
			});
		}
		
		if ($('.slider-blog').length) {
			$('.slider-blog').slick({
				arrows: true,
				dots: false,
				autoplay: true,
				slidesToShow: 3,
				pauseOnFocus: false,
				pauseOnHover: false,
				responsive: [
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 1,
							vertical: false,
							swipe: true
						}
					}
				]
			});
		}
		
		if ($('.slider-logos').length) {			
				$('.slider-logos').slick({
					arrows: false,
					dots: false,
					slidesToShow: 12,
					slidesToScroll: 1,
					autoplaySpeed: 500,
					autoplay: true,
					pauseOnFocus: false,
					pauseOnHover: false,
					responsive: [
						{
							breakpoint: 1100,
							settings: {
								slidesToShow: 3,
								vertical: false,
								swipe: true
							}
						}
					]
				});			
		}
	},
	handleWaypoints: function() {

		_main.debug?console.log('waypoints init'):'';
		  
		
		var waypoint = new Waypoint({
			element: $('#trigger_nav'),
			handler: function(direction) {
				if (direction == "down") {
					// console.log('down');
					$('header').addClass('scrolled');
				} else {
					// console.log('up');
					$('header').removeClass('scrolled');
				}
			}			
		});

		if ($('main').hasClass('homepage')) {		
		
		var waypoint_offer = new Waypoint({
			element: $('#offer'),
			handler: function(direction) {
				if (direction == "down") {
					_main.debug?console.log('trigger: offer'):'';
					$('#offer .bg-img').addClass('init');
					$('#offer').addClass('init');
				} else {
					// console.log('up');
					// $('header').removeClass('scrolled');
				}
			}			
		});
		var waypoint_blog = new Waypoint({
			element: $('#blog'),
			handler: function(direction) {
				if (direction == "down") {
					_main.debug?console.log('trigger: blog'):'';
					$('#blog .bg-img').addClass('init');
				} else {
					// console.log('up');
					// $('header').removeClass('scrolled');
				}
			},
			offset: '50%'	
		});
		var waypoint_about = new Waypoint({
			element: $('#about'),
			handler: function(direction) {
				if (direction == "down") {
					_main.debug?console.log('trigger: about'):'';
					// $('#bar-1').val($('#bar-1').attr('data-val'));
					// $('#bar-2').val($('#bar-2').attr('data-val'));
					// $('#bar-3').val($('#bar-3').attr('data-val'));
					$('.bar').removeClass('init');
					$('#about').addClass('init');
				} else {
					// console.log('up');
					// $('header').removeClass('scrolled');
				}
			},
			offset: '50%'
		});
		var waypoint_extraitems = new Waypoint({
			element: $('#extra_items'),
			handler: function(direction) {
				if (direction == "down") {
					_main.debug?console.log('trigger: extra_items'):'';
					// $('#bar-1').val($('#bar-1').attr('data-val'));
					// $('#bar-2').val($('#bar-2').attr('data-val'));
					// $('#bar-3').val($('#bar-3').attr('data-val'));
					$('.extra-items').addClass('init');
				} else {
					// console.log('up');
					// $('header').removeClass('scrolled');
				}
			},
			offset: '50%'
		});
		var waypoint_infoextra = new Waypoint({
			element: $('#info-extra'),
			handler: function(direction) {
				if (direction == "down") {
					_main.debug?console.log('trigger: extra_items'):'';
					// $('#bar-1').val($('#bar-1').attr('data-val'));
					// $('#bar-2').val($('#bar-2').attr('data-val'));
					// $('#bar-3').val($('#bar-3').attr('data-val'));
					$('.info-extra').addClass('init');
				} else {
					// console.log('up');
					// $('header').removeClass('scrolled');
				}
			},
			offset: '65%'
		});

		}
	
	},
	botOrNot: function() {
		_main=this;
		(_main.debug)?console.log('bot verification'):'';
		grecaptcha.ready(function() {
			grecaptcha.execute(recap_token, {action: 'homepage'})
			.then(function(token) {							
				$.ajax({
	              url: base_url+'/bot_or_not.php',  
	              type: 'POST',
	              data: {	              	
	              	token: token
	              },
	            }).done(function(response) {
                	// (_main.debug)?console.log('response content: ',response):'';
                	(_main.debug)?console.log('response score: ',response):'';
	                if (response >= 0.5) {
	                	(_main.debug)?console.log('Assuming that you are a human'):'';
	                	_main.visitorType='human';
	                } else {
	                	(_main.debug)?console.log('Assuming that you are a bot'):'';
	                	_main.visitorType='bot';
						_main.fuckBots();
	                }	                    
	            });
   			});
		});
	},
	fuckBots: function() {
		$('form').remove();
	},
	events: function () {
		_main = this;
		
		$('.pd').on('click', function(e) {
			e.preventDefault();
		});		

		$('header button.menu').on('click', function() {
			$(this).toggleClass('opened');				
			$('header nav').toggleClass('show');	
			$('body').toggleClass('has-mobile-nav');
		});

		$('.quick-form .close-pop').on('click', function() {
			$('.quick-form').removeClass('show');
		});

		$(document).on('submit','.quick-form form',function(){
			// alert('ok');
		 });

		if ($('main').hasClass('single')) {

			$('nav a').on('click', function (e){
				e.preventDefault();

				var target = $(this).attr('href');
				var lang_code = $('main').attr('data-lang');		

				if (target == '#pop') {
					$('.quick-form').addClass('show');
				} else {
					if (lang_code == 'pl') {
						window.location = base_url+target;
						// console.log('GO TO: ',base_url+target);
					} else {
						window.location = base_url+'/en'+target;
						// console.log('GO TO: ',base_url+'/en'+target);
					}
				}

			});
		} else {
			if (_main.viewport == 'mobile' || _main.viewport == 'tablet') {
				
				$('header nav a').on('click', function(e) {
					
					e.preventDefault();
					
					$('header button.menu').click();
					
					if ($(this).attr('href') == '#pop') {
						
						$('.quick-form').addClass('show');

					} else {
						
						var target = $(this).attr('href')
						var target_offset = $(target).offset().top;
						
						$('html, body').animate({scrollTop:target_offset}, 500);

					}
				});

			} else {
				$('header li a').on('click', function(e) {				
					e.preventDefault();

					if ($(this).attr('href') == '#pop') {
						
						$('.quick-form').addClass('show');

					} else {
						var target = $(this).attr('href')
						var target_offset = $(target).offset().top;
						
						$('html, body').animate({scrollTop:target_offset}, 500);
					}
					
				});
			}
		}

		// $("body").keyup(function(e) {
		// 	// console.log('keycode: ',e.keyCode);
		// 	switch (e.keyCode) {
		// 	   case 8:
			   
		// 		alert('W: '+$(window).width()+' / H: '+$(window).height()+' / Ratio: '+window.devicePixelRatio);
			   
		// 		  break;
		 
		// 	}
		//  });

		
	},
	getViewport: function () {
		_main = this;
		wh = $(window).width();
		if (wh <= _main.breakpointMobile) {
			_main.viewport = 'mobile';
		} else if (wh > _main.breakpointMobile && wh <= _main.breakpointTablet) {
			_main.viewport = 'tablet';
		} else {
			_main.viewport = 'desktop';
		}
		(_main.debug) ? console.log('viewport: ', _main.viewport) : '';		
	},
	init: function () {
		_main = this;
		_main.getViewport();
		_main.events();					
		_main.handleSliders();					
		_main.botOrNot();					
		_main.handleWaypoints();					
		_main.cookies.checkCookies();
	},

	debug: true,
	viewport: null,
	globalNavState: false,
	breakpointMobile: 640,
	breakpointTablet: 1100,
	cookieName: 'ilscontent'
}

$(document).ready(function () {
	app.init();
});

$(window).on('load', function () {
	app.windowLoaded();
});

$(window).resize(function () {
	app.windowResized();
});

$(document).scroll(function () {
	app.windowScrolled();
});
